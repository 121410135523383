/* LGEUS-126 20200525 add */
@charset 'UTF-8';
@import './setting/variables';

.join-us-banner {
    display: none;
    position: relative;
    z-index: 10001;
    background: $bg-white;
    color: $color-nightrider;
    width: auto;
    /* LGEUS-126 20200601 add */
    margin: 0 -15px;
    /* //LGEUS-126 20200601 add */
    padding: 0;
    border-bottom: 2px solid $line-darkgray;
    &.sticky-active {
        position: fixed;
        top: 0;
        width: 100%;
        border-bottom: none;
        box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.35);
        @include screen(custom, max, $max-sm) {
            box-shadow: none;
        }
    }
    &.active {
        display: block;
    }
    .inner {
        position: relative;
        width: 100%;
        max-width: $max-design+px;
        margin: 0 auto;
        padding: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        @include screen(custom, max, $max-sm) {
            padding: 20px 20px 0;
        }
        .close {
            position: absolute;
            top: 22px;
            right: 30px;
            display: block;
            cursor: pointer;
            a {
                display: block;
                width: 20px;
                height: 20px;
                background-image: url('/lg5-common/images/common/icons/modal-close.svg');
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 20px 20px;
            }
            @include screen(custom, max, $max-sm) {
                top: 16px;
                right: 16px;
            }
        }
        .join-us-title {
            .title {
                @include font-family($font-semibold);
                font-weight: 400;
                font-size: 26px;
                color: $color-black;
                line-height: 30px;
                @include screen(custom, max, $max-sm) {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
            .desc {
                margin-top: 3px;
                margin-bottom: 22px;
                @include font-family($font-regular);
                font-weight: 400;
                font-size: 14px;
                color: $color-black;
                line-height: 20px;
                .more {
                    cursor: default;
                    &:hover {
                        text-decoration: none;
                    }
                }
                @include screen(custom, max, $max-sm) {
                    margin-bottom: 0;
                    font-size: 16px;
                    line-height: 24px;
                    .more {
                        &:after {
                            content: '';
                            position: static;
                            display: inline-block;
                            margin: -4px -5px -3px 8px;
                            width: 20px;
                            height: 20px;
                            background: url('/lg5-common/images/common/icons/link-open-gray.svg') no-repeat 50% 50%;
                            vertical-align: middle;
                        }
                        &.open {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
            @include screen(custom, max, $max-sm) {
                padding-bottom: 15px;
            }
        }
        .column-box {
            display: flex;
			flex-wrap: wrap;
			flex-direction: row;
            .column {
                margin-bottom: 33px;
                padding-left: 20px;
                width: 403px;
                box-sizing: border-box;
                &:first-child {
                    padding-left: 0;
                }
                &.column-btn {
                    padding: 27px 0 0 15px;
                    width: calc(100% - 403px*3);
                    button {
                        width: 143px;
                    }
                }
                label {
                    margin-bottom: 7px;
                    @include font-family($font-semibold);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    @include screen(custom, max, $max-sm) {
                        margin-bottom: 5px;
                        font-size: 14px;
                    }
                }
                input {
                    width:100%;
                }
                @include screen(custom, max, 1440) {
                    width: calc(25% - 20px);
                }
                @include screen(custom, max, $max-md) {
                    width: 50%;
                    margin-bottom: 30px;
                    &:nth-child(3) {
                        padding-left: 0;
                    }
                }
                @include screen(custom, max, $max-sm) {
                    width: 100%;
                    padding-left: 0;
                    &.column-btn {
                        margin-top: 30px;
                        margin-bottom: 15px;
                        padding: 0;
                        width: 100%;
                        button {
                            width: 100%;
                        }
                    }
                    &:nth-child(1) {
                        order: 1;
                    }
                    &:nth-child(2) {
                        order: 2;
                    }
                    &:nth-child(3) {
                        order: 3;
                    }
                    &:nth-child(4) {
                        order: 5;
                    }
                }
                @include screen(custom, max, $max-sm) {
                    margin-bottom: 16px;
                }
            }
            .join-us-chk {
                .field-block {
                    margin-bottom: 0;
                }
                .checkbox-box {
                    .text{
                        font-size: 14px;
                        color: $color-nightrider;
                    }
                }
                .link-text {
                    line-height: 24px;
                    text-decoration: underline;
                    @include screen(custom, max, $max-md) {
                        display: inline-block;
                    }
                    @include screen(custom, max, $max-sm) {
                        display: inline-block;
                    }
                }
                @include screen(custom, max, $max-sm) {
                    padding: 16px 0 30px;
                    border-bottom: 1px solid $line-gray;
                    order: 4;
                }
            }
            @include screen(custom, max, $max-sm) {
                display: none;
                padding-top: 13px;
                padding-bottom: 15px;
                border-top: 1px solid $line-gray;
                &.open {
                    display: flex;
                }
            }
        }
        .join-us-complete {
            padding: 55px 0 34px;
            text-align: center;
            .title {
                margin-bottom: 7px;
                @include font-family($font-semibold);
                font-weight: 400;
                font-size: 32px;
                color: $color-black;
                line-height: 40px;
            }
            .desc {
                @include font-family($font-regular);
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
    @include screen(custom, max, $max-sm) {
        border-bottom: none;
    }
}
/* //LGEUS-126 20200525 add */
