.footer-box {
	background: $bg-white;
	margin:0 -15px;
	word-break:break-word;
	@include screen(print) {
		display:none;
	}
	.skip_nav {
		max-width:1280px;
		margin:0 auto;
	}
	.footer-component {
		max-width:(1284px + 60px);
		margin:0 auto;
		padding: 22px 0 30px;
		@include screen(custom, max, 913){
			padding: 0 24px 40px;
		}
		.footer-caution {
			font-size:14px;
			line-height:20px;
			padding:25px 0;
			color:$color-dimgray;
			.caution-header {
				padding-bottom:25px;
				margin-bottom:25px;
				border-bottom:1px solid $line-gray;
			
			}
			a {
				color:$color-carmine;
				text-decoration:underline;
			}
			p { 
				margin:0 0 19px; 
				&:last-child {
					margin:0;
				}
			}
			strong, b {
				@include font-family($font-bold);
			}
		}
		.sub-contents.social {
			@extend %clearfix;
			padding:20px 0;
			@include screen(custom, max, $max-sm){
				padding:20px 0 18px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:before,
				&:after {
					content: none !important;
				}
			}
			@include screen(custom, max, 415){
				display: block;
			}
			.country-information {
				padding: 6px 0 0;
				@include screen(custom, min, $min-md){
					float:left;
				}
				@include screen(custom, max, $max-sm){
					padding: 0;
				}
				a {
					font-size: 14px;
					line-height: 20.02px;
					@include font-family($font-regular);
					padding-left: 28px;
					display: block;
					background: url('/lg5-common/images/common/icons/country.svg') left top no-repeat;
					background-size: 25px 20px;
				}
			}
			.social-links {
				font-size:0;
				@include screen(custom, min, $min-md){
					float:right;
				} 
				@include screen(custom, max, $max-sm){
					margin-top: 0;
				}
				@include screen(custom, max, 415){
					margin-top: 26px;
				}
				li {
					display:inline-block; 
					/* PJTWAUS-1 modify */
					.social-icon {
						position: relative;
						width: 24px;
						height: 24px;
						display: inline-block;
						margin-left: 16px;
						img {width:100%;height:auto;}
						@include screen(custom, max, $max-sm){
							margin-left:0px;
							margin-right:12px;							
						}
						&.icon-facebook {
							background:url('/lg5-common/images/common/icons/facebook-circle-fill.svg') left top no-repeat;
						}
						&.icon-twitter {
							background:url('/lg5-common/images/common/icons/twitter-circle-fill.svg') left top no-repeat;
						}
						&.icon-google {
							background:url('/lg5-common/images/common/icons/google-circle-fill.svg') left top no-repeat;
						}
						&.icon-pinterest {
							background:url('/lg5-common/images/common/icons/pinterest-circle-fill.svg') left top no-repeat;
						}
						&.icon-youtube {
							background:url('/lg5-common/images/common/icons/youtube-circle-fill.svg') left top no-repeat;
						}
						&.icon-email {
							background:url('/lg5-common/images/common/icons/email-circle-fill.svg') left top no-repeat;
						}
						&.icon-instagram {
							background:url('/lg5-common/images/common/icons/instagram-circle-fill.svg') left top no-repeat;
						}
						&.icon-linked-in {
							background:url('/lg5-common/images/common/icons/linked-in-fill.svg') left top no-repeat;
						}
						&.icon-spiceworks {
							background:url('/lg5-common/images/common/icons/spiceworks-fill.svg') left top no-repeat;
						}
						&.icon-snapchat {
							background:url('/lg5-common/images/common/icons/snapchat-fill.svg') left top no-repeat;
						}
						
					}					
					&:last-child {
						.social-icon {
							margin-right:0;
						}						
					}
					/* //PJTWAUS-1 modify */
				}	
			}
		}
		.footer-contents {
			@extend %clearfix;
			padding-top:30px;
			padding-bottom:50px;
			display:table;
			@include screen(custom, max, $max-lg) {
				width:100%;
				display:block;
			}
			.no-link {
				font-size:14px;
				line-height:16px;
				color:$color-dimgray;
			}
			a {
				font-size:14px;
				line-height:16px;
				color: #757575;
				&.social-support-fb {
					display:inline-block;
					line-height:20px;
					margin-bottom: 8px;
					&:before {
						display:inline-block;
						content:'';
						margin-right:8px;
						width:20px;
						height:20px;
						vertical-align:middle;
						background:url('/lg5-common/images/common/icons/facebook-circle-line-m.svg') left top no-repeat;
					}
				}
				&.social-support-tw {
					display:inline-block;
					line-height:20px;
					&:before {
						display:inline-block;
						content:'';
						margin-right:8px;
						width:20px;
						height:20px;
						vertical-align:middle;
						background:url('/lg5-common/images/common/icons/twitter-circle-line-m.svg') left top no-repeat;
					}
				}
			}
			ul {
				margin-bottom:11px; /* PJTWAUS-1 20200220 modify */
				
				li {
					margin-bottom:8px;
					&.social {
						margin-bottom:4px;
						a {
							color:$color-nightrider;
						}
					}
					&:last-child {
						margin-bottom:0;
					}
				}
			}
			.footer-title {
				margin-bottom: 16px; /* PJTWAUS-1 20200220 modify */
				.no-link, 
				a {
					font-size:17px; 
					line-height:21px;
					@include font-family($font-regular);
					color:$color-nightrider;
				}
				+ .support-text {
					+ .footer-title {
						margin-top:29px;
					}
				}
				&:last-child {
					margin-bottom: 16px !important; /* PJTWAUS-1 20200220 modify */
				}
			}
			.footer-sub-title {
				a {
					font-size:16px;
					line-height:24px; 
					@include font-family($font-regular);
					color:$color-black;
				}
				+ ul {
					margin-top:4px;
					margin-bottom:11px;
					li {
						padding-left:10px;
					}
				}
				
			}
			.support-text {
				margin-bottom: 16px;
			}
			ul {
				+ .footer-title {
					margin-top:28px;	
				}
			}
			
			
			.link-bottom {
				.link-wrap {
					margin-bottom:0;
				}
			}
			.footer-contents-box {
				width:16.978%;
				font-size:0;
				display:table-cell;
				vertical-align:top;
				
				/* PJTWAUS-1 20200220 modify */  
				.footer-title {
					a, h5, h6{
						@include font-family($font-regular);
						color: #000;
					}
					h6, a {
						font-size: 14px;
						line-height: 14.7px;
						margin-top: 16px;
					}
					h5 {
						font-size: 18px;
						line-height: 21.6px;
						margin-bottom: 16px;
					}

					&:first-child {
						> a {
							margin-top: 0;
							font-size: 17px;
						}
					}
				}
				.footer-sub-title,
				.link-wrap > ul > li:not([class]),
				.link-wrap > .box-wrap > ul > li:not([class]) {
					a {
						display: block;
						font-size: 14px;
						line-height: 21.6px;
						padding-bottom: 0;
						margin-bottom: 16px;
						color: #757575;

						h6 {
							font-size: 14px;
						}
					}
					
					ul {
						li {
							padding-left: 16px;
							a {
								font-size: 14px;
								line-height: 21.6px;
								color: #757575;
								margin-top: 16px;
							}
						}
					}
					
				}
				
				ul {
					margin-top: 5px;
					margin-bottom: 9px; /* //PJTWAUS-1 202002120 modify */ 
					li{
						a{
							font-size: 14px;
							line-height: 16px;
							color: $color-dimgray;
						}
					}
				}
				/* //PJTWAUS-1 20200220 modify */
				
				/* PJTWAUS-1 modify */
				li.link-wrap { 
					width:25%;
					display:inline-block;
					vertical-align:top;
					padding-right:25px;
					margin-bottom: 19px;
					&:nth-child(5),
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8){
						margin-bottom:0;
					}
					/*
					.link-about-lg {
						margin-top:55px;
						@include screen(custom, max, $max-lg) {
							margin-top:0;
						}
					}
					*/
					&.box-wrap {
						margin-bottom:0;  
						+ .box-wrap {
							margin-top:24px;
						}
					}
				}
				/*.box-wrap {
					+ .box-wrap {
						margin-top:24px;
					}
				}*/
				&.column-4 {
					width:65.9%;
					.box-wrap {
						+ .box-wrap {
							margin-top:0px;
							.link-wrap {
								margin-bottom:0;
							}
						}
					}
				}
				&.products-columns {
					width: 49.9%;
					li.link-wrap { 
						width: 50%;

						@include screen(custom, max, $large) {
							width: 33%;
						}
					}
				}
				&.column-1 {
					border-left: 1px solid #ccc;
					.link-wrap {
						width:100%;
						padding-left:25px;
						@include screen(custom, max, $max-lg) {
							padding-left:0;
							//width:50%;
							padding-right:0;
							margin-bottom:0;
							&.box-wrap {
								+ .box-wrap { 
									margin-top:0;
								}
								//width:50%;
								width:25%;
								padding-right:25px;
								display:inline-block;
								vertical-align:top;
							}
						}
					}
					ul {
						padding-bottom:5px;
					}
					.support-text {
						display:inline-block;
						
					}
					&:last-child {
						@include screen(custom, max, $max-lg) {
							.box-wrap {
								&:nth-child(2){
									//width:50%;   /* //PJTWAUS-1 20200122 modify */
									.support-text {
										width:100%;  /* //PJTWAUS-1 20200122 modify */
										+ .support-text {
											margin-top:0;  /* //PJTWAUS-1 20200114 modify */
										}
										+ .link-text {
											margin-top:0px;
											vertical-align:top;
											line-height:16px;
										}
									}
								} 
							}
						}
						@include screen(custom, max, $max-md) {
							.box-wrap {
								width:25%;
								&:nth-child(2){
									width:50%;
									.support-text {
										width:50%;
										+ .support-text {
											margin-top:0px;
										}
										+ .link-text {
											margin-top:0px;
											vertical-align:top;
											line-height:16px;
										}
									}
								} 
								&:last-child {
									margin-top:24px;
								}
							}
						}
					}

					@include screen(custom, max, $large) {
						padding: 0 10px;
					}
				}
				span:not(.no-link) {
					display:block;
					font-size:14px;
					line-height: 125%;
					margin-bottom:8px;
					color: #757575;
					&:last-child {
						margin-bottom:0;
					}
				}
				// @include screen(custom, max, $max-lg) {
				// 	width:100%;
				// 	display:block;	
				// 	border-right:0;
				// 	border-bottom:1px solid $line-gray;
				// 	border-left: none;
				// 	padding-bottom:19px;
				// 	margin-bottom:22px;
				// 	&:last-child {
				// 		border-bottom:0;
				// 		margin-bottom:0;
				// 		padding-bottom:0;
				// 	}
				// 	&.column-4 {
				// 		width:100%;
						
				// 	}
					
				// }
				.ico-right {
					line-height:24px;
					&:after {
						background:url('/lg5-common/images/common/icons/page-right.svg') 0 1px no-repeat;
					}
				}
				.ico-blank {
					text-decoration:none;
					&:hover {
						text-decoration:underline;
					}
					&:after {
						background:url('/lg5-common/images/common/icons/blank-dimgray.svg') 0 0 no-repeat;
					}
				}
				&:last-child {
					border-right:0;
					.link-wrap {
						padding-right:0;
						&:first-child {
							.box-wrap {
								.footer-title {
									&:last-child {
										margin-bottom:10px; /* PJTWAUS-1 20200220 modify */
									}
								}
							}
						}
					}
					@include screen(custom, max, $max-lg) {
						.link-wrap {
							width:100%;
							/*
							.box-wrap {
								width:100%;
								.support-text {
									width:160px;
									margin-top:0;
									
								}
								.ico-right {
									margin-top:0;
									vertical-align:top;
								}
							}
							*/
						}
					}
					
				}
				
			}
		}
	}
	/* //PJTWAUS-1 modify */
	.footer-bottom-box {
		background:	#000;
		border: 1px solid $line-darkgray;
		
		&[data-multishop=N] {
			min-height: 345px;

			@include screen(custom, max, 415){
				min-height: 746px;
			}
		}

		.footer-bottom {
			margin:0 15px;
			padding:0 15px;
			.footer-component {
				max-width:1344px;
				margin:0 auto;
				padding: 40px 0 22px;
				font-size:0;
				.bottom-links {
					position:relative;
					.links-left {											
						width:calc(100%-564px);
						min-width:364px;
						/* PJTWAUS-1 modify */
						.footer-util-box {
							max-width: 600px;
							margin-bottom: 26px;
							li {
								display:inline-block;
								a {
									font-size:18px;
									line-height:21.6px;
									color:$color-white;
									@include font-family($font-regular);
									display:inline-block;
									padding: 0;
									margin-right: 32px;
									margin-bottom: 24px;
									position:relative;									
								}
							}
						}	

						@include screen(custom, max, $large) {
							width: auto;
						}
					}
					/* //PJTWAUS-1 modify */
					.links-right {
						position:absolute;
						right:70px;  /* //PJTWAUS-1 20200122 modify */
						top: 163px;
						transform:translate(0,-50%);
						border-radius: 8px;
						width: 257px;
						height: 64px;
						line-height: 64px;
						background: $bg-white url('/lg5-common/images/common/footer/icon-jungdo.jpg') 0 0 no-repeat;
						background-position: 0 4.5px;
						overflow:hidden;
						a {
							font-size: 14px;
							line-height: 17.5px;
							color: #000;
							@include font-family($font-regular);
							text-transform:none;
							padding-left:72px;
							display:inline-block;
							vertical-align:middle;
							padding-right: 28px;
							&:after {
								background: none;
							}	
						}

						.icon-right {
							position: absolute;
							right: 16px;
							top: 37%;
							background: url('/lg5-common/images/common/icons/link-right-nightrider.svg') no-repeat;
							background-size: 10px 20px;
							height: 20px;
							width: 10px;
						}
					}
					/* PJTWAUS-1 20200312 modify */ 
					.connect-link {
						a { 
							color: #b2b2b2;
						}
					}
					/* // PJTWAUS-1 20200312 modify */ 
				}
				
				.copyright {
					margin-top:5px;
					font-size:14px;
					line-height:14.7px;
					color:#757575;
					@include font-family($font-regular);

					.footer-logo {
						display: block;
						margin-bottom: 15px;
					}
				}	
			}
		}
	}

	.footer-component,
	.hidden-mobile > .footer-component,
	.visible-mobile> .footer-component {
		@media (min-width: 1024px) and (max-width: 1360px) {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}
.footer-box .visible-mobile {
	.footer-component .footer-contents {
		margin-bottom:0;
		padding-bottom:0;
		padding-top:24px;
		border-top: 1px solid #ccc;
		.footer-contents-box {
			border-bottom:0;
			padding-bottom:0;
			margin-bottom:26px;

			&:before,
			&:after {
				content: none;
			}

			/* PJTWAUS-1 modify */
			.footer-title {				
				font-size:18px;
				line-height:24px;
				color:$color-black;
				margin-bottom:7px;
			}
			.box-wrap {
			   margin-bottom:5px;
			}
			.link-wrap {  /* //PJTWAUS-1 0114 modify */
				display:block;
				width:100%;
				padding-right:0;
				margin-bottom:0;
				.box-wrap {  /* //PJTWAUS-1 0114 modify */
					.footer-title {
						.no-link,
						a {
							font-size:17px;
							line-height:21px;
							color:$color-nightrider;
							@include font-family($font-semibold);
						}
					}
					.support-text {
						margin-top:0;
						span {
							font-size:14px;
							line-height:22px;
							
						}
					}
					.footer-title .support-text {
						width:100%;
						padding-top:7px;
					}
					.ico-right {
						margin-top:9px;
					}
				}
				.box-wrap {  /* //PJTWAUS-1 0114 modify */
					+ .box-wrap {
						margin-top:17px; /* //PJTWAUS-1 0220 modify */
					}
					&:first-child {
						// @extend %clearfix;
						.footer-title {
							float:left;
							width:100%; /* PJTWAUS-1 20200220 modify */
							margin-bottom:5px; /* PJTWAUS-1 20200220 modify */
							margin-top:13px; /* PJTWAUS-1 20200220 modify */
						}
						/* PJTWAUS-1 20200220 modify */
                        .half{
	                          .footer-title {
								  float: none;
								  margin: 0;
							  }
					    }
					    /* //PJTWAUS-1 20200220 modify */
					}
					span {
						margin-bottom:0;
					}
					&:last-child {
						ul {
							@extend %clearfix;
							li {
								margin-bottom:0;
							}
						}
						@extend %clearfix;
						div.social {
							margin-bottom:0;
						}
					}
				}
			}
			ul.m-support-list {				
				.half {
					display:inline-block;
					width: 50%;
					margin:0 !important;
					.footer-title {
						margin-bottom:0;
					}
				}
			}
			&.site-list {
				margin-bottom:9px;
				display: block;
				width: 100%;
				li.link-wrap {
					border-top: 1px solid #ccc;
					
					&:last-child {
						border-bottom: 1px solid #ccc;
					}
				}

				.category-block { 
					
					&:first-of-type {
						.depth-category {
							border-top: none !important;
						}
					}
				}
			}
			&:not(.site-list) {
				margin-bottom: 43px;
				display: flex;
				justify-content: space-between;
				width: 100%;
				align-items: start;

				.link-wrap {
					width: 50%;
					position: relative;
					margin-top: 0;

					> .box-wrap {
						margin-bottom: 0;

						&:nth-child(2) {
							margin-top: 0;
	
							.footer-title {
								margin-top: 0;
							}
						}
					}

					&:first-child {
						display: flex;
						flex-wrap: wrap;
						align-items: start;

						.box-wrap {
							flex-basis: 50%;
							&:last-child {
								margin-top: 0;
							}
						}
					}

					&:last-child {
						border-left: 1px solid #ccc;
						padding-left: 23px;

						.box-wrap {
							.footer-title {
								margin-top: 0;
								margin-bottom: 32px;
							}

							ul {
								display: flex;
								justify-content: space-between;
								align-items: center;
								flex-wrap: wrap;
								width: 100%;

								.footer-sub-title {
									flex-basis: 50%;

									&:last-child,
									&:nth-last-child(2){
										a {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}
				}

				@include screen(custom, max, 590) {
					display: block;

					.link-wrap{
						width: 100%;

						&:last-child {
							border-left: 0;
							padding-left: 0;
							padding-top: 40px;
							margin-top: 40px;
							border-top: 1px solid #ccc;
						}
					}
				}
			}			
		}
		/* //PJTWAUS-1 modify */
		.depth-1 {
			position:relative;
			display:block;
			margin-bottom:0 !important;
			@include font-family($font-semibold);
			&.has-category {
				background:url('/lg5-common/images/common/icons/accordion-open-nightrider.svg') 100% center no-repeat;
			}
			&.on {
				background:url('/lg5-common/images/common/icons/accordion-close-nightrider.svg') 100% center no-repeat;
				border-bottom: 1px solid #ccc;
				a {
					&.no-link {
						span {
							&:after {display:none;}
						}
					}
				}
			}
			a {
				display:block;
				padding: 24px 0;
				span {
					font-size: 18px;
					line-height: 120%;
					color: $color-black;
					position: relative;
					display: inline-block;
					@include font-family($font-regular);
				}
			}
			
		}
		.depth-2 {
			position:relative;
			display:none;
			overflow:hidden;
			margin: 0;
			
			.depth-category {
				position:relative;
				display:block;
				padding-left: 16px;
				margin-bottom:0;
				.category-button {
					@include font-family($font-regular);
					padding: 24px 0;
					display: block;
					text-decoration: none;
					span {
						font-size: 18px;
						line-height: 21.6px;
						color: #000;
						position: relative;
						display: inline-block;
					}
				}
				&.has-category {
					.category-button {
						background:url('/lg5-common/images/common/icons/accordion-expand-nightrider.svg') right center no-repeat;

						border-bottom: 1px solid #ccc;
					}
					&.on {
						.category-button {
							background:url('/lg5-common/images/common/icons/accordion-fold-nightrider.svg') right center no-repeat;
						}
					}
				}
			}

			.category-block {
				margin-bottom:-1px;

				&:last-child {
					.depth-category.has-category {
						.category-button {
							border-bottom: 0;
						}
					}
				}
			}

			.depth-1-category-link {
				position:relative;
				display:block;
				padding:0 10px;
				a {
					@include font-family($font-regular);
					padding:15px 0 16px;
					display:block;
					font-size:16px;
					line-height:1;
					color:$color-black;
				}
			}

		}
		.depth-1-list,
		.depth-2-list {
			position:relative;
			display:none;
			overflow:hidden;
			margin: 0;
			ul {
				margin: 0;
				padding: 24px 0;
			}
			
			li {
				
				&:last-child {
					padding-bottom:0;
				}
				.no-link,
				a {
					font-size: 16px;
					line-height: 1;
					color: #000;
					padding-left: 16px;
					display: block;

				}
			}

			.category-block {
				> ul {
					> li {
						margin-bottom: 24px;
						a {
							color: #757575;
							font-size: 14px;
							line-height: 14.7px;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		.button-layer {
			position:absolute;
			width:40px;
			height:100%;
			z-index:2;
			right:0;
			top:0;
			background:none;
		}
		.depth-2-list {
			.depth-2-category-link {
				a {
					@include font-family($font-semibold);
					color:$color-black;
				}
			}
			&.in-depth {
				li {
					padding-left: 16px;
					margin-bottom: 24px;

					&.depth-title {
						padding-left:0;
					}

					a,
					a span {
						@include font-family($font-regular);
						font-size: 14px;
						line-height: 14.7px;
						color: #757575;
						display:inline-block;
						position:relative;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				li + li.depth-title {
					padding-top:0;
				}
			}
		}
	}
	.footer-bottom-box {
		.footer-bottom {
			position: relative;
			padding:0 24px;
			margin:0;
			.footer-component {
				/* PJTWAUS-1 modify */
				.bottom-links { 					
					li {
						display: inline-block;
						a {
							font-size: 18px;
							line-height: 21.7px;
							color: #fff;
							@include font-family($font-regular);
							display:inline-block;
							padding-right: 32px;
							position: relative;
							margin-bottom: 24px;							
						}

						@include screen(custom, max, 415){
							display: block;
						}
					}					
					
				}
				/* //PJTWAUS-1 modify */
				.copyright {
					margin-top:10px;
					font-size:14px;
					line-height:18px;
				}
				
			}
			.links-bottom {
				border-radius: 8px;
				height: 64px;
				width: 257px;
				background: $bg-white;
				position: absolute;
				bottom: 16%;
				right: 25px;
				.links-wrap { 
					display:table;
					width:100%;
					height:100%;
					a {
						font-size:14px;
						line-height:19px;
						text-transform:none;
						color: #000;
						display:table-cell;
						width:100%;
						height:100%;
						vertical-align:middle;
						@include font-family($font-regular);
						padding-left:80px;
						padding-right:24px;
						background:url('/lg5-common/images/common/footer/icon-jungdo-m.jpg') 14px center no-repeat;
						&:after {
							background:url('/lg5-common/images/common/icons/link-right-nightrider.svg') 0 1px no-repeat;
						}	
					}
				}

				@include screen(custom, max, 415){
					position: relative;
					margin-left: 24px;
				}
			}
		}
	}
}
.hidden-mobile {display:block;}
.visible-mobile {display:none;}
@include screen(custom, max, 1023) {
	.hidden-mobile { display:none;}
	.visible-mobile {display:block;}
}
//b2b footer 
.footer-box .business .footer-component {
	.footer-contents {
		@include screen(custom, max, $max-sm) {
			padding-top:0;
			// margin-top:0;
		}
		.footer-contents-box {
			width:20%;
			padding:0 25px;
			&:first-child {
				padding-left:0;
				/* PJTWAUS-1 modify */
				&.box-wrap {
					@extend %clearfix;
				}
				/* //PJTWAUS-1 modify */
			}
			/* PJTWAUS-1 20200121 modify */
			.footer-title {
				a, .no-link {
					@include screen(custom, max, $max-sm) {
						font-size:17px;
					line-height:21px;
					color:$color-nightrider;
					@include font-family($font-semibold);
					}					
				}
			}
			/* //PJTWAUS-1 20200121 modify */			
			&.site-list {
				@include screen(custom, max, $max-sm) {
					padding-bottom:10px;
				}
				.link-wrap {
					@include screen(custom, max, $max-sm) {
						&:first-child {
							border-top:1px solid $line-gray;
						}
					}
				}
			}
			&.support {
				@include screen(custom, max, $max-lg) {
					padding:0 25px 0 0;
					
				}
			}
			/* PJTWAUS-1 modify */
			li.link-wrap {
				width:100%;
				padding-right:0;
				@include screen(custom, max, $max-md) {
					&:first-child {
						.box-wrap {
							margin-top:-8px;
						}
					}
					&:last-child {
						margin-bottom:0;
					}
				
				}
				&:only-child {
					margin-bottom:0;
				}				
				
			}
			.inner-box-wrap {
				@include screen(custom, max, $max-lg) {
					padding-bottom:12px;
				}
			}
			@include screen(custom, max, $max-lg) {
				display:table-cell;
				border-bottom:0;
				border-right:1px solid $line-gray;
				padding:0 25px 0 25px;
				width:25%;
				&:first-child {
					padding:0;
					width:100%;
					display:block;
					border-right:0;
					border-bottom:1px solid $line-gray;
					li.link-wrap {
							width:25%;
							display:inline-block;
							vertical-align:top;
						.footer-title {
							margin:0 0 24px 0;
							padding:0 25px;
						}
						&.box-wrap {
							margin-top:0;
						}	
						/* PJTWAUS-1 20200114 modify */
						/*.footer-title {
							&:nth-child(1),
							&:nth-child(5) {
								padding:0 25px 0 0;
							}
						}*/
						&:nth-child(1),
						&:nth-child(5) {
							.footer-title {
								padding:0 25px 0 0;
							} 
						}	
						/* //PJTWAUS-1 20200114 modify */					
					}	
					+ .footer-contents-box {
						padding-left:0;
					}									
				}
				&:last-child {
					border-right:0;
				}															
			}
			/* //PJTWAUS-1 modify */
			@include screen(custom, max, $max-sm) {
				padding:0;
				&:first-child {
					border-bottom:0;
					padding-bottom:0;
					margin-bottom:10px;
				}
				&.contents-top {
					padding:0;
					margin-bottom:0;
					margin-top:15px; /* //PJTWAUS-1 20200220 modify */ 
					/* PJTWAUS-1 modify */
					li.link-wrap {
						width: 50%;
						&.box-wrap {
							margin-top:0;
							+ .box-wrap {
								margin-top:0;
							}
							.footer-title {
								padding:0;
							}
							&.contact {
								.footer-title {
									float:left;
									width:100%;    /* //PJTWAUS-1 20200121 modify */
									margin:0 0 7px 0;
								}
								.support-text {
									clear:both;
									vertical-align:top;
									padding-top:7px;  /* //PJTWAUS-1 20200121 modify */
									a {
										margin-top:0;
										line-height:22px; 
									}
								}
							}
						}
						/* //PJTWAUS-1 modify */
					}
				}
			}			
					
		}
	}
	.footer-bottom-box { 
		.connect-link {
			margin-top:5px;
			a {
				font-size:14px;
				line-height:24px;
				color:#b2b2b2;
				text-decoration:underline;
			}
		}
		.footer-bottom {
			.footer-component {
				.links-left {
					a {
						margin-bottom:3px;
					}
				}
				.copyright {
					margin-top:2px;
				}
				@include screen(custom, max, $max-md) {
					.bottom-links {
						position:relative;
						
						.links-left {
							a {
								margin-bottom:5px;
							}
						}
						
						.links-right {
							top:0;
							transform:none;
						}
					}	
					.copyright {
						margin-top:0;
					}
				}
				@include screen(custom, max, $max-sm) {
					padding-bottom:25px;
					.bottom-links {
						margin-bottom:10px;
					}

					.connect-link {
						margin-top:8px;
					}
				}
			}
		}   
	}
}


