$color_1: white;
$color_2: #000;
$background-color_1: #000;
$background-color_2: white;
$background-color_3: transparent;

.support-gallery {
	position: relative;
	background: #000;
	padding: 12px 0;
	position: relative;
	margin: 0;
	clear: both;
	font-size: 14px;
	margin-left: -15px;
	margin-right: -15px;
	text-rendering: geometricPrecision;
}
.gallery-notices-bar-wrap {
	position: relative;
	color: $color_1;
	display: block;
	width: 100%;
}
.gallery-notices-bar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.gallery-notices-bar-content {
	flex-grow: 1;
	overflow: hidden;
	display: flex;
	align-items: stretch;
	.gallery-notices-bar-content-link {
		color: $color_1;
		font-size: 16px;
		line-height: 1.25;
		text-decoration: none;
		border-bottom: solid 2px white;
		font-weight: 600;
		white-space: nowrap;
	}
	.gallery-notices-bar-content-icon {
		width: 20px;
		height: 20px;
		flex-grow: 0;
		flex-shrink: 0;
		font-size: 0;
		svg {
			color: $color_1;
		}
	}
	.gallery-cta {
		text-decoration: underline;
		font-weight: 600;
		z-index: 2;
	}
}
.gallery-title {
	text-align: left;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	width: 100%;
	min-width: calc(100% - 256px);
	padding: 0 128px;
	box-sizing: content-box;
	background-color: $background-color_1;
}
.gallery-title-main {
	padding-top: 5px;
	p {
		display: inline;
		margin-right: 8px;
	}
	a {
		display: inline;
		margin-right: 8px;
	}
}
.gallery-copy {
	position: relative;
}
.gallery-copy-alert {
	position: absolute;
	top: 100%;
	width: 300px;
	left: 50%;
	transform: translateX(-150px);
	background-color: $background-color_2;
	border-radius: 8px;
	padding: 5px;
	color: $color_2;
	font-size: 12px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.gallery-notices-bar-cta {
	top: 0;
	right: 40px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-basis: 146px;
	width: 92px;
	height: 100%;
	z-index: 5;
	button {
		background-color: $background-color_3 !important;
		width: 20px;
		height: 20px;
		border: none;
		padding: 0;
		cursor: pointer;
		&:focus {
			outline: none;
		}
		svg {
			vertical-align: baseline;
		}
	}
}
.gallery-notices-bar-page {
	font-size: 14px;
	height: 100%;
	display: inline;
}
@media (max-width: 899.95px) {
	.support-gallery {
		padding: 12px 0;
	}
	.gallery-notices-bar-cta {
		right: 8px;
		width: 68px;
	}
	.gallery-notices-bar {
		justify-content: left;
	}
	.gallery-title {
		justify-content: left;
		font-size: 16px;
		min-width: calc(100% - 88px);
		padding: 0 80px 0 8px;
		background-color: $background-color_1;
	}
	.gallery-notices-bar-content {
		.gallery-notices-bar-content-link {
			font-size: 16px;
		}
	}
	.gallery-title-main {
		text-align: left;
		padding-top: 4px;
	}
}
