@font-face {
    font-display: swap;
    font-family: 'LGEI Headline';
    font-style: normal;
    font-weight: 700;
    src: local("LGEI Headline Bold"),url(https://media-sandbox.us.lg.com/m/5e52a52c31c6bab4/original/LGEI-Headline-Bold.woff) format("woff")
}

@font-face {
    font-display: swap;
    font-family: 'LGEI Headline';
    font-style: normal;
    font-weight: 300;
    src: local("LGEI Headline Light"),url(https://media-sandbox.us.lg.com/m/59725909fc4ab1c/original/LGEI-Headline-Light.woff) format("woff")
}

@font-face {
    font-display: swap;
    font-family: 'LGEI Headline';
    font-style: normal;
    font-weight: 400;
    src: local("LGEI Headline Regular"),url(https://media-sandbox.us.lg.com/m/5c7442b73b8ce8c5/original/LGEI-Headline-Regular.woff) format("woff")
}

@font-face {
    font-display: swap;
    font-family: 'LGEI Headline';
    font-style: normal;
    font-weight: 600;
    src: local("LGEI Headline Semibold"),url(https://media-sandbox.us.lg.com/m/328f00665631ef5/original/LGEI-Headline-Semibold.woff) format("woff")
}

@font-face {
    font-display: swap;
    font-family: 'LGEI Text';
    font-style: normal;
    font-weight: 700;
    src: local("LGEI Text Bold"),url(https://media-sandbox.us.lg.com/m/1501aec51861592b/original/LGEI-Text-Bold.woff) format("woff")
}

@font-face {
    font-display: swap;
    font-family: 'LGEI Text';
    font-style: normal;
    font-weight: 300;
    src: local("LGEI Text Light"),url(https://media-sandbox.us.lg.com/m/7e515c0e50a961f1/original/LGEI-Text-Light.woff) format("woff")
}

@font-face {
    font-display: swap;
    font-family: 'LGEI Text';
    font-style: normal;
    font-weight: 400;
    src: local("LGEI Text Regular"),url(https://media-sandbox.us.lg.com/m/32ac952d48049ac7/original/LGEI-Text-Regular.woff) format("woff")
}

@font-face {
    font-display: swap;
    font-family: 'LGEI Text';
    font-style: normal;
    font-weight: 600;
    src: local("LGEI Text Semibold"),url(https://media-sandbox.us.lg.com/m/44032565f746cf42/original/LGEI-Text-SemiBold.woff) format("woff")
}
@font-face {
	font-family: 'LG Smart Bold';
	font-display: swap;
	font-weight: normal;
	font-style: normal;
	src: local('☺'),
		 url('/lg5-common/webfonts/LG_Smart_Bold.woff2') format('woff2'),
		 url('/lg5-common/webfonts/LG_Smart_Bold.woff') format('woff');
}
@font-face {
	font-family: 'LG Smart Bold';
	font-display: swap;
	font-weight: normal;
	font-style: italic;
	src: local('☺'),
		 url('/lg5-common/webfonts/LG_Smart_Bold_Italic.woff2') format('woff2'),
		 url('/lg5-common/webfonts/LG_Smart_Bold_Italic.woff') format('woff');
}
@font-face {
	font-family: 'LG Smart Light';
	font-display: swap;
	font-weight: normal;
	font-style: normal;
	src: local('☺'),
		 url('/lg5-common/webfonts/LG_Smart_Light.woff2') format('woff2'),
		 url('/lg5-common/webfonts/LG_Smart_Light.woff') format('woff');
}
@font-face {
	font-family: 'LG Smart Regular';
	font-display: swap;
	font-weight: normal;
	font-style: normal;
	src: local('☺'),
		 url('/lg5-common/webfonts/LG_Smart_Regular.woff2') format('woff2'),
		 url('/lg5-common/webfonts/LG_Smart_Regular.woff') format('woff');
}
@font-face {
	font-family: 'LG Smart Regular';
	font-display: swap;
	font-weight: normal;
	font-style: italic;
	src: local('☺'),
		 url('/lg5-common/webfonts/LG_Smart_Regular_Italic.woff2') format('woff2'),
		 url('/lg5-common/webfonts/LG_Smart_Regular_Italic.woff') format('woff');
}
@font-face {
	font-family: 'LG Smart SemiBold';
	font-display: swap;
	font-weight: normal;
	font-style: normal;
	src: local('☺'),
		 url('/lg5-common/webfonts/LG_Smart_SemiBold.woff2') format('woff2'),
		 url('/lg5-common/webfonts/LG_Smart_SemiBold.woff') format('woff');
}

