@charset 'UTF-8';
@import './setting/variables';

$mkt-float : (
	bg-graywhite : #e5e5e5,
	bg-black : $color-nightrider
);

.floating-menu {
	position:fixed;
	opacity:1;
	transition:opacity 0.8s ease-out, transform 0.5s ease-out;
	z-index:100;
	&.call-yet {
		opacity:0;
		z-index:-100;
		// right:-40px !important; 
		//transition:opacity 0.3s ease-out, right 0.3s ease-out;
		transform: translateX(110px);
	}
	&.top {
		right: 7%;
		bottom: 20px;
	}
	.back-to-top {
		width:50px; height:50px;
		background:url(/lg5-common/images/common/icons/arrow-up-nightrider.svg) 50% 50% no-repeat rgba( map-get($mkt-float, bg-graywhite), 0.75) ;
		background-size:20px 20px;
		border-radius:50%;
		margin-top:15px;
		line-height:0;
		button{
			width:100%;
			height:100%;
			padding:0;
			border:none;
			background:none;
			cursor:pointer;
		}
	}
	&.chat {right:20px;bottom:(20px+50px+15px)}
	.chatbot-linker {
		width:60px; height:60px;
		background:#ea1917 url(/lg5-common/images/common/lg-chat.gif) 50% 50% no-repeat;
		background-size:56px 56px;
		border-radius:50%;
		box-shadow:0px 2px 5px hsla(0,0%,0%,0.3);
		a {
			display:block;
			width:100%;
			height:100%;
			//LGEUS-12424 add
			color:#fff;
			&.head-inner.js-popup:before {
				content: "";
				position: absolute;
				top: 44px;
				left: 40px;
				height: 13px;
				width: 13px;
				background-color: #ea1917;
				-ms-transform: skew(24deg, 5deg) rotate(10deg);
				transform: skew(24deg, 5deg) rotate(10deg);
				clip-path: rect(3px 14px 14px 3px);
				border-radius: 0 3px 3px 3px;
				-webkit-box-shadow: 0 2px 5px rgba(0,0,0,.3);
				box-shadow: 0 0px 2px rgba(0,0,0,.3);
			}
		}
		.bubble-wrapper {
			height:40px;
			width: 150px;
			position: absolute;
			right: 66px;
			overflow:hidden;
			bottom: 23px;
			.bubble {
				background-color: $color-nightrider;
				bottom: -10px;
				color: #fff;
				font-size: 12px;
				min-width: 5px;
				white-space: nowrap;
				padding:10px;  
				border-radius: 9px 9px 0 9px;
				position:relative;
				left: 150px;
			}
			@include screen(custom, max, 1439) {
				.bubble {
					padding: 10px 12px;
				}
			}
		}
		a#zendesk-launcher:hover {
			cursor: pointer;
		}
	}

	@include screen(custom, max, $max-sm){
		&.call-yet {
			transform:translateX(98px);
		}
		&.top{
			// right:13px;
			bottom:15px;
			transform:translateX(7px);
		}
		&.chat {
			// right:13px; 
			bottom:(15px+45px+15px);
			transform:translateX(0);
		}
		.back-to-top,
		.chatbot-linker {width:45px;height:45px;}
	}
}
